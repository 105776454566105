import styled from "@emotion/styled";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import { PARAGRAPH_HORIZONTAL_PADDING_STRING } from "segments/desktop/core-components/full-paragraph/full-paragraph";
export const StyledFullParagraphComponent = styled.div`
  max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;

  .full-paragraph-wrapper {
    max-width: 1100px;
    margin: auto;
    display: flex;
    justify-content: center;
    padding: 0px ${PARAGRAPH_HORIZONTAL_PADDING_STRING};
  }
`;
