import styled from "@emotion/styled";
export const StyledTwoThirdsTextAndAssetComponent = styled.div`
  .side-by-side-text-and-asset-component {
    .side-by-side-content {
      .full-paragraph-component-wrapper {
        width: 40%;
      }

      .image-wrapper {
        width: 60%;
      }

      &.no-text-component {
        justify-content: center;

        .image-wrapper {
          width: 100%;
        }
      }
    }
  }
`;
